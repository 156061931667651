$container-padding: 1rem;

$spacing-size-large: 2rem;
$spacing-size-normal: 1rem;
$spacing-size-small: 0.5rem;

@function container-padding($multiply: 1) {
  @return $container-padding * $multiply;
}

$textfield-border-radius-small: 10px;
$textfield-border-radius: 26px;

@function textfield-border-radius($size: normal) {
  @if $size == small {
    @return $textfield-border-radius-small;
  }
  @return $textfield-border-radius;
}

@mixin unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin element-spacing {
  $text-elements: input, ".dropdown", button, p, ol, ul, h1, h2, h3, h4, h5;
  @each $text-element in $text-elements {
    @each $sub-text-element in $text-elements {
      #{$text-element} + #{$sub-text-element} {
        margin-top: container-padding();
      }
    }
  }
}
