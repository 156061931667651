@import "./utils/reset.css";
@import "./utils/colors";
@import "./utils/typography";
@import "./utils/buttons";
@import "./utils/layout";
@import "./utils/animations";

html,
body {
  height: 100%;
}
body {
  background-color: lighter-grey();
  margin: 0;
  font-family: font-family();
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.inactive {
    overflow: hidden;
  }

  @include font-normal();
}

#root {
  height: 100%;
}

#modal-root {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: modal-black-color();

  &.active {
    display: flex;
    justify-content: center;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .wrapper {
      min-width: 620px;

      width: 620px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-height: 90%;

      .header {
        background-color: dark-color();
        min-height: 80px;
        position: relative;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: font-normal();
        width: 100%;
      }

      > div:not(.header) {
        width: 100%;
        background-color: off-white();
        &:not(.create-new-project):not(.translate-state) {
          overflow: auto;
        }
        padding: 1rem;
        box-sizing: border-box;

        &.message {
          text-align: center;
          min-height: 3.3rem;
        }

        &.error {
          color: error-color();
        }
      }
    }
  }

  .modal-close-button {
    position: absolute;
    right: $spacing-size-normal;
    top: $spacing-size-large;
    width: $spacing-size-large;
    height: $spacing-size-large;
    opacity: 0.7;
  }
  .modal-close-button:hover {
    opacity: 1;
  }
  .modal-close-button:before,
  .modal-close-button:after {
    position: absolute;
    left: $spacing-size-normal;
    content: " ";
    height: $spacing-size-normal;
    width: 2px;
    background-color: white-color();
  }
  .modal-close-button:before {
    transform: rotate(45deg);
  }
  .modal-close-button:after {
    transform: rotate(-45deg);
  }
}

// No color functions or otherwise is use in the login to keep the standard Demant login UI.
.login {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;

  &__outer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__inner {
    box-sizing: border-box;
    width: 440px;
    height: 338px;
    background-color: white-color();
    display: flex;
    flex-direction: column;
    padding: 44px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
    justify-content: space-between;
    align-items: center;
  }
}

@include buttons();
@include typography();
@include element-spacing();
