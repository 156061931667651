@import "./colors";
@import "./layout";

$font-default: "Open Sans", sans-serif;

$font-size-xx-large: 2.5rem;
$font-size-large: 1.25rem;
$font-size-default: 16px;
$font-size-small: 14px;

@function font-family() {
  @return $font-default;
}

@mixin font-xx-large {
  font-size: $font-size-xx-large;
}
@mixin font-large {
  font-size: $font-size-large;
}
@mixin font-normal {
  font-size: $font-size-default;
}

@mixin font-small {
  font-size: $font-size-small;
}

@mixin font-bold {
  font-weight: 700;
}

@mixin font-semi-bold {
  font-weight: 600;
}

@mixin font-light {
  font-weight: 300;
}

@mixin input-text($size:regular) {
  display: flex;
  justify-content: center;
  align-items: center;

  @include font-normal;

  padding: container-padding() container-padding(2);
  border-radius: textfield-border-radius(large);
  background-color: white-color();

  color: dark-color();

  text-decoration: none;
  border: none;
  outline: 0;

  @if $size == large {
    width: 90%
  }

  @else {
    width: 25rem;
  }

}


@mixin typography {

  h1 {
    @include font-xx-large();
    @include font-bold;
    color: dark-color();
  }

  h2 {
    @include font-large();
    @include font-bold;
    color: dark-color();
  }

  a {
    text-decoration: none;
  }

  input[type="text"], textarea {
    @include input-text;
    &.large {
      @include input-text(large);
    }
  }

  .font__bold {
    @include font-bold;
  }
  .font__small {
    @include font-small;
  }
}
