@import "./colors";
@import "./typography";
@import "./layout";

$button-width-small: container-padding(7);
$button-width-large: container-padding(18);
$button-padding: container-padding(1);
$button-border-radius: container-padding(2);

@mixin standardbutton {
  cursor: pointer;
  background: transparent;
  border: 0;
  @include unselectable();
}

@mixin roundButton($color, $size) {
  @include standardbutton();
  @include font-normal();
  font-family: font-family();
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color;
  border-radius: $button-border-radius;
  color: white-color();
  @include font-semi-bold;
  text-align: center;
  @if $size == small {
    min-width: $button-width-small;
    width: $button-width-small;
    //height: $button-width-small/5;
  }
  @if $size == large {
    min-width: $button-width-large;
    width: $button-width-large;
    //height: $button-width-large/5;
  }
  border-style: none;
  padding: $button-padding;
  align-items: flex-start;
  box-sizing: border-box;
  line-height: normal;
  white-space: nowrap;

  &:hover {
    background-color: rgba($color, 0.8);
  }

  &:disabled {
    background-color: $dark-grey;
    cursor: default;
    &:hover {
      background-color: $dark-grey;
    }
  }
}

@mixin buttons {
  .btn__light {
    @include roundButton(light-color(), small);
  }
  .btn__light--large {
    @include roundButton(light-color(), large);
  }
  .btn__dark {
    @include roundButton(dark-color(), small);
  }
  .btn__dark--large {
    @include roundButton(dark-color(), large);
  }
}

@mixin threeDotsButton {
  background-color: transparent;
  $width: 20px;
  $height: 28px;
  $dot-size: 8px;
  $line-size: $dot-size/2;
  $line-width: 22px;

  width: $width;
  height: $height;
  border: none;
  position: relative;
  cursor: pointer;
  &:focus {
    outline: none;
  }

  &:after,
  &:before,
  span {
    width: $dot-size;
    height: $dot-size;
    border-radius: 100px;
    position: absolute;
    left: 0px;
    background: lighter-dark-color();
    transform: rotate(0deg);
    transition: all 0.4s;

    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &:after,
  &:before {
    content: "";
  }

  &:after {
    top: 0;
  }

  &:before {
    bottom: 0;
  }

  span {
    top: $height/2 - $dot-size/2;
  }

  &.on {
    &:after {
      transform: rotate(135deg);
      top: $height/2 - $line-size/2;
      width: $line-width;
      height: $line-size;
    }

    &:before {
      transform: rotate(225deg);
      top: $height/2 - $line-size/2;
      width: $line-width;
      height: $line-size;
    }

    span {
      transform: rotate(135deg);
      display: none;
    }
  }
}
