$darkblue: #24405b;
$lighterdarkblue: #5a7084;
$lightblue: #3344F6;
$white: #ffffff;
$off-white: #f5f5f5;
$lighter-grey: #EDEDEA;
$light-grey: #dadad5;
$dark-grey: #b9b9b9;
$link-blue: #61dafb;
$black: #000000;
$error-color: red;
$yellow: #f7ad02;
$tableEvenRowGray: #F8F8F7;
$searchGray: #919FAD;
$textHighlightBlue: #076ade;

@function modal-black-color($opacity: 0.8) {
  @if opacity != 1 {
    @return rgba($black, $opacity);
  }
  @return $black;
}

@function dark-color($opacity: 1) {
  @if opacity != 1 {
    @return rgba($darkblue, $opacity);
  }
  @return $darkblue;
}

@function lighter-dark-color($opacity: 1) {
  @if opacity != 1 {
    @return rgba($lighterdarkblue, $opacity);
  }
  @return $lighterdarkblue;
}

@function light-color($opacity: 1) {
  @if opacity != 1 {
    @return rgba($lightblue, $opacity);
  }
  @return $lightblue;
}

@function white-color() {
  @return $white;
}

@function off-white() {
  @return $off-white;
}

@function light-grey() {
  @return $light-grey;
}

@function lighter-grey() {
  @return $light-grey;
}

@function dark-grey() {
  @return $dark-grey;
}

@function error-color() {
  @return $error-color;
}

@function link-color() {
  @return $link-blue;
}
